import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseCreateDeal } from 'src/app/core/data/deal/deal.model';
import { DealProvider } from 'src/app/core/data/deal/deal.provider';
import { Event } from 'src/app/core/data/event/event.model';
import { EventProvider } from 'src/app/core/data/event/event.provider';
import { Product } from 'src/app/core/data/product/product.model';
import { SearchService } from '../../search/service/search.service';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  // public event$: Observable<Event>;
  public event: Event;
  public product?: Product;

  public modalDealShow = false;
  public showGallery = false;
  public galleryImg: string;
  public processing = false;
  public dealRes?: ResponseCreateDeal;

  public modalDealSuccessShow = false;

  public translations = region_phrases[environment.region_id as keyof RegionNames];
  public environment = environment;

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phoneNumber: new UntypedFormControl('', [Validators.required]),
    recaptchaResponse: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl(''),
  });

  private paramsSubscribe: Subscription;

  private lastUrl?: string;

  // lógica acrescentada para voltar para a página de busca
  private cameFromSearch = false;


  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly eventData: EventProvider,
    private readonly dealProvider: DealProvider,
    private searchService: SearchService,

  ) {
    this.lastUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  }

  ngOnInit(): void {
    this.paramsSubscribe = this.route.params.subscribe((params) => {
      this.product = undefined;
      this.eventData.info(params.eventId).pipe(
        tap((event: Event) => {
          this.product = event.lots.find((x) => x.id === parseInt(params.id))!;
          if(environment.region_id == "bo")
          {
            this.product.paymentConditions = this.product.paymentConditions.replace('R$', 'BOB');
            this.product.paymentConditionObservation = this.product.paymentConditionObservation.replace('R$', 'BOB');
          }
          this.event = event;
          for (const field of event.dealExtraFields) {
            this.form.addControl(field.name, new UntypedFormControl(''))
          }
        }),
        tap(() => {
          setTimeout(() => this.initSlider(), 10);
        }),
      ).subscribe();
    });
    this.checkNavigationOrigin();
  }

  ngOnDestroy(): void {
    this.paramsSubscribe.unsubscribe();
  }

  clearSlider(): void {
    // $('#lightSlider').lightSlider().destroy();
    // $('.lSGallery').remove();
  }

  initSlider(): void {
    this.clearSlider();

    $('#lightSlider').lightSlider({
      gallery: true,
      item: 1,
      thumbItem: 6,
      slideMargin: 0,
      speed: 500,
      auto: false,
      loop: true,
      onBeforeSlide: () => this.pauseVideo()
    });
  }

  onClickNegociar(): void {
    this.dealRes = undefined;
    this.modalDealShow = true;
    this.modalDealSuccessShow = false;
    setTimeout(() => {
      this.form.reset();
    }, 200);
  }

  onClickConfirmNegociar(): void {
    if (!this.product) return;
    this.processing = true;
    this.dealProvider
      .create({
        ...this.form.value,
        extraFields: this.event.dealExtraFields.reduce((obj, f) => { obj[f.name] = this.form.controls[f.name].value; return obj }, {} as Record<string, string>),
        lotId: this.product.id,
        dealType: this.product.saleType,
        value: this.product.saleType === 'shopping' ? this.product.unitValue : this.product.nextBid,
      })
      .subscribe((res) => {
        this.dealRes = res;
        this.processing = false;
        if (res.success === true) {
          this.modalDealShow = false;
          this.modalDealSuccessShow = true;
        }
      });
  }

  fieldInvalid(name: string): boolean {
    const field = this.form.get(name)!;
    return field.invalid && (field.dirty || field.touched);
  }

  pauseVideo() {
    $('.yt-product-video').each(function () {
      $(this).attr("src", $(this).attr("src"));
    });
  }

  goBack() {
    if (this.lastUrl && this.router.url.includes(this.lastUrl)) {
      console.log('goback history')
      this.location.back();
      return;
    }
    this.router.navigate(['../..'], { relativeTo: this.route })

    {// lógica acrescentada para voltar para a página de busca
      if (this.cameFromSearch) {
        this.router.navigate(['/busca']);
      } else {
        window.history.back();
      }
    }
  }

  openGallery(img: string) {
    this.showGallery = true;
    this.galleryImg = img;
  }

  // lógica acrescentada para voltar para a página de busca
  private checkNavigationOrigin() {
    this.route.queryParams.subscribe(params => {
      this.cameFromSearch = params['from'] === 'busca';
    });
  }

  private navigateToSearch() {
    this.router.navigate(['/busca']);
  }

  private navigateToPreviousPage() {

  }
}
