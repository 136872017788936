import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { EventListModule } from '../event/event.module';





@NgModule({
  declarations: [
    SearchComponent,
    SearchBarComponent,
    SearchResultsComponent

  ],
  imports: [
    CommonModule,
    SearchRoutingModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    MatToolbarModule,
    ReactiveFormsModule,
    ComponentsModule,
    EventListModule,



    RouterModule.forChild([
      {
        path: '',
        component: SearchComponent,
      }
    ]),

  ],
  exports: [
    SearchComponent,
    SearchBarComponent,
    SearchResultsComponent
  ]


})
export class SearchModule { }
