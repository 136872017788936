import { Product } from 'src/app/core/data/product/product.model';
import { ProductProvider } from 'src/app/core/data/product/product.provider';
import { Observable, tap } from 'rxjs';
import { Partner } from '../../data/partner/partner.model';
import { Segment } from '../../data/segment/segment.model';
import { HeaderLink } from './interfaces/header-link';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Banner } from '../../data/banner/banner.model';
import { BannerProvider } from '../../data/banner/banner.provider';
import { SegmentProvider } from '../../data/segment/segment.provider';
import { PartnerProvider } from '../../data/partner/partner.provider';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit {

  public lots: Product[];
  public activeSegments: (Segment & { events?: Event[] })[];

  public links: HeaderLink[] = [
    {
      route: 'https://www.remateweb.com/',
      text: 'Transmissões Ao Vivo',
    },
    {
      route: 'https://www.remateweb.com/agenda',
      text: 'Agenda',
    },
    {
      route: 'https://www.remateweb.com/play',
      text: 'Remate Play',
    },
  ];
  
  public translations = region_phrases[environment.region_id as keyof RegionNames];

  public constructor(
    private readonly productProvider: ProductProvider,
    private readonly segmentProvider: SegmentProvider,
    private readonly breakpointObserver: BreakpointObserver,
  ) {

  }

  public ngOnInit(): void {
  }

  public onSearch(){
  }
}
