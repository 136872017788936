import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventProvider } from 'src/app/core/data/event/event.provider';
import { Event } from 'src/app/core/data/event/event.model';
import { Product } from 'src/app/core/data/product/product.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LeadProvider } from 'src/app/core/data/lead/lead.provider';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';

declare var mnt: any;

type SortOptions = 'high-price' | 'low-price' | '';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit, AfterViewInit {
  public event$: Observable<Event>;
  public lots: Product[];
  public sort: SortOptions = '';

  public translations = region_phrases[environment.region_id as keyof RegionNames];

  public event: Event;

  public showGallery = false;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', [Validators.required,]),
  });

  public modalLeadSuccessShow = false;
  public mostrarDivAcima: boolean;

  constructor(private readonly route: ActivatedRoute, private readonly eventData: EventProvider, private readonly leadData: LeadProvider) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.event$ = this.eventData.info(params.id).pipe(
        tap((event) => {
          this.event = event;
          this.lots = event.lots;
          this.sortLots();

          this.mostrarDivAcima = event.saleType === 'pre-bid';
        }),
      );
    });
  }

  ngAfterViewInit(): void {
    mnt('scout');
    mnt('capture');
  }

  onSortChange(value: string): void {
    this.sort = value as SortOptions;
    this.sortLots();
  }

  sortLots(): void {
    const sorts: Record<SortOptions, (a: Product, b: Product) => number> = {
      '': (a, b) => 0,
      'high-price': (a, b) => b.totalValue - a.totalValue,
      'low-price': (a, b) => a.totalValue - b.totalValue,
    };
    this.lots = this.lots.sort(sorts[this.sort]);
  }

  lead() {
    const { email, name, phone } = this.form.value;
    this.leadData.create({
      auctionId: this.event.id,
      email, name, phone
    }).subscribe((res) => {
      this.form.reset();
      this.modalLeadSuccessShow = true;
    });
  }

  openGallery() {
    this.showGallery = true;
  }
}
