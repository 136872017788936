<div class="container" *ngIf="events && segment">
  <h2 class="my-4 ps-3">
    <strong>{{ segment.description }}</strong>
  </h2>
  <div class="events row">
    <div class="col-12 col-lg-6" *ngFor="let event of events">
      <div class="card m-1 p-2" style="cursor: pointer" [routerLink]="['/eventos', event.id]">
        <div class="d-flex" style="min-width: 200px; gap: 20px">
          <div class="justify-content-between" style="flex: 0 0 200px">
            <img [src]="event.imageUrl" class="img-fluid" style="object-fit: cover; width: 200px" />
          </div>
          <div class="row" style="max-width: calc(100% - 200px);">
            <div class="" style="max-width: 280px">
              <h4>{{ event.title }}</h4>
              <ng-container *ngIf="event.saleType == 'pre-bid'">
                <span *ngIf="event.date">
                  <span class="small"> <small>{{ translations.date_start }}</small> </span> <br />
                  <span>
                    {{ event.date | date: 'dd/MM/yy' }} às {{ event.date | date: 'HH:mm' }}h
                  </span>
                </span>
                <br />

                <span *ngIf="event.endDate">
                  <span class="small"> <small>{{ translations.date_end }}</small> </span> <br />
                  <span>
                    {{ event.endDate | date: 'dd/MM/yy' }} às {{ event.endDate | date: 'HH:mm' }}h
                  </span>
                </span>

                <div class="mt-4 d-none d-sm-block">
                  <app-timer [endAt]="event.endDate" size="small"></app-timer>
                </div>
              </ng-container>
              <ng-container *ngIf="event.saleType == 'shopping'">
                <div class="event-description">
                  <span [innerHtml]="event.strippedDescription()"></span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="mt-4 d-block d-sm-none" *ngIf="!event.hideTimers" >
          <app-timer [endAt]="event.endDate" size="small"></app-timer>
        </div>
      </div>
    </div>
  </div>
</div>
