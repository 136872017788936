import { debounce, debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { searchProductsData } from './../data-mock/search-products';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { query } from 'express';
import { fromEvent, Subject } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  //standalone: true,
  //imports: [],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent implements OnInit {
  searchTerm: string = '';

  products: any[] = [];

  searchSubject: Subject<string> = new Subject();

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(200), distinctUntilChanged()).subscribe((searchTerm) => {
      if (searchTerm.trim().length >= 3) {
        this.router.navigate(['/busca'], { queryParams: { query: searchTerm } });
      }
    });
  }

  onInputChange(): void {
    if (this.searchTerm.trim().length >= 3) {
      this.searchSubject.next(this.searchTerm);
    }
  }

  onSearch(): void {
    this.searchSubject.next(this.searchTerm);
    this.searchTerm = '';
    this.cdRef.detectChanges();
  }
}
