import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/components/components.module";
import { ProductDetailsComponent } from "../product/product-details/product-details.component";
import { EventDetailsComponent } from "./event-details/event-details.component";
import { DialogModule } from 'primeng/dialog';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { ReactiveFormsModule } from "@angular/forms";
import { environment } from "src/environments/environment";
import { EventListCategoryComponent } from "./event-list-category/event-list.component";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { TimerModule } from "src/app/components/timer/timer.module";
import { CategoryGuard } from 'src/app/guards/category.guard';


const imports = [
  CommonModule,
  ComponentsModule,
  ReactiveFormsModule,
  DialogModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  PipesModule,
  TimerModule,
];

const providers = [
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptchaKey,
    } as RecaptchaSettings,
  },
]

@NgModule({
  declarations: [
    EventDetailsComponent,
    ProductDetailsComponent,
  ],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: ':id',
            component: EventDetailsComponent,
          },
          {
            path: ':eventId/produtos',
            children: [
              {
                path: ':id',
                component: ProductDetailsComponent,
              },
            ],
          },
        ],
      }
    ]),
    ...imports
  ],
  providers,
})
export class EventModule { }

@NgModule({
  declarations: [
    EventListCategoryComponent,
  ],
  imports: [
    RouterModule.forChild([
      {
        path: ':id/eventos',
        component: EventListCategoryComponent,
        canActivate: [CategoryGuard]
      }
    ]),
    ...imports
  ],
  providers,
})
export class EventListModule { }