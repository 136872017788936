import { take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  Subject,
  takeUntil,
} from 'rxjs';
import { Product } from 'src/app/core/data/product/product.model';
import { ProductProvider } from 'src/app/core/data/product/product.provider';
import { SearchService } from '../service/search.service';
import { Lot } from '../model/lot';
import { Category } from '../model/category';

@Component({
  selector: 'app-search-results',
  //standalone: true,
  //imports: [],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
})
export class SearchResultsComponent implements OnInit {
  countResults: number = 0;
  private lastUrl?: string;

  public event: Event;

  //products: Product[] = [];
  @Input()
  searchTerm: string = '';

  suggestedSearch: string | null = null;

  @Input()
  public product: Product;
  private destroy$ = new Subject<void>();

  public lot: Lot;

  products: Lot[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly productProvider: ProductProvider,
    private searchService: SearchService,
  ) {
    this.lastUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searchTerm = params['query'];
      if (this.searchTerm) {
        debounceTime(200);
        this.fetchProducts(this.searchTerm);
      }
    });
  }

  fetchProducts(query: string): void {
    this.searchService
      .getProductLine(query)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe({
        next: (data: Category[]) => {
          this.products = data.flatMap((category) => category.lots) || [];
          console.log('Produtos encontrados:', this.products);
          this.countResults = this.products.length;
        },
        error: (error) => {
          console.error('Erro ao buscar produtos:', error);
        },
        complete: () => {},
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  productDetail(auctionId: number, productId: number): void {
    this.router.navigate(['/eventos', auctionId, 'produtos', productId]);
  }

  goToProductDetails(productId: number) {
    this.searchService.setCameFromSearch(true);
    this.router.navigate(['/produtos', productId], { queryParams: { from: 'search' } });
  }
}
