<div class="select-container select-container-fluid m-auto"

 style="display: flex; align-items: center; border-radius: 12px; padding: 5px; position: relative; background-color: #F3F5F7; left: 0; justify-content: center; height: 100%;">
  <img src="assets/search_.png"
       width="24"
       height="24"
       alt="Ícone de Pesquisa"
       class="icon" />

  <input type="text"
         placeholder="O que você procura?"
         class="input-group mb-6"
         style="border: none; outline: none; background-color: #F3F5F7; font-size:1rem;"
         [(ngModel)]="searchTerm"
         (keyup.enter)="onSearch()"
         (input)="onInputChange()"
  >

  <button class="mat-button_label"
          style="background-color: var(--blue); color: white; border-radius: 20px; height: auto; font-size: 1rem"
          mat-flat-button
          (click)="onSearch()">Buscar
  </button>
</div>









