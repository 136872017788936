import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SearchResultsComponent } from "./search-results/search-results.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";

const routes: Routes = [
  { path: '', component: SearchResultsComponent },
  //{ path: 'busca', component: SearchResultsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule {}
