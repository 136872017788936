import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EventProvider } from 'src/app/core/data/event/event.provider';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryGuard implements CanActivate {

  constructor(private eventProvider: EventProvider, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const categoryId = route.params['id'];
    return this.eventProvider.all({ categoryId: categoryId }).pipe(
      map(events => {
        // encaminha direto para o evento caso exista apenas 1 evento na categoria
        if (events.length === 1) {
          this.router.navigate(['/eventos', events[0].id]);
          return false; 
        }
        // Navega normal na página de categorias
        return true; 
      })
    );
  }
}

