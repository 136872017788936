import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from 'src/app/core/data/api.client';
import { HttpClient } from '@angular/common/http';
import { Category } from '../model/category';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private readonly apiClient: ApiClient,
    private http: HttpClient,
  ) {}
  public cameFromSearch = false;

  setCameFromSearch(value: boolean) {
    this.cameFromSearch = value;
  }

  public getProductLine(query: string): Observable<Category[]> {
    const apiUrl = 'https://api.remateweb.com/api/';
    const endpoint = '/shopping/search';
    const url = `${apiUrl}${endpoint}?query=${query}`;

    return this.http.get<Category[]>(url);
  }
}
