<div class="footer mt-1 mt-sm-2">
  <section class="footer-content">
    <div class="row no-gutters justify-content-center align-items-center">
      <div class="col-2">
        <img
          src="assets/logos/logo_white.png"
          style="max-width: 75px"
          alt="Logo"
          [routerLink]="['/']"
        />
      </div>
      <div class="col-10">
        <div class="row no-gutters align-content-center">
          <div class="col-auto info-column" *ngFor="let info of infoColumns">
            <h3 class="info-title">
              {{ info.title }}
            </h3>
            <a *ngFor="let page of info.pages" [href]="page.route" target="_blank">
              {{ page.title }}
            </a>
          </div>
          <div class="col-auto info-column">
            <!--
            <a class="d-inline-flex d-sm-none p-0 ms-0 me-2" href="https://bolivia.remateagroshop.com">
              <img src="/assets/Flag_orb_Bolivia.png" style="width: 26px; height: auto;"/>
            </a>
            -->
            <a class="d-inline-flex d-sm-none p-0 ms-2 me-0" href="https://remateagroshop.com">
              <img src="/assets/Flag_orb_Brazil.png" style="width: 26px; height: auto;"/>
            </a>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
  <div class="bottom-info">
    <section>
      <div class="row no-gutters justify-content-center">
        <div class="col align-self-center text-start">© Remate Agroshop {{ currentYear }}</div>
        <div class="col-auto">
          <button mat-button *ngFor="let link of socialMediaLinks" (click)="goTo(link.url, link.newTab)">
            <img [src]="link.image.src" [alt]="link.image.alt" [ngStyle]="{width: link.image.width, height: link.image.height}" />
          </button>

          <!--
          <a class="d-none d-sm-inline-flex" mat-button href="https://bolivia.remateagroshop.com">
            <img src="/assets/Flag_orb_Bolivia.png" style="width: 26px; height: auto;"/>
          </a>
          -->
          <a class="d-none d-sm-inline-flex" mat-button href="https://remateagroshop.com">
            <img src="/assets/Flag_orb_Brazil.png" style="width: 26px; height: auto;"/>
          </a>

        </div>
      </div>
    </section>
  </div>
</div>
