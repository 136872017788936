<div class="container" *ngIf="event$ | async; let event">
  <h2 class="mt-3 mb-1 text-center text-sm-start">{{ event.title }}</h2>
  <div class="partner-info mb-3" *ngIf="mostrarDivAcima">
    <div class="row" >
      <div class="col-sm-4 text-center text-sm-start">
        <img class="img-cover img-fluid rounded" [src]="event.imageUrl" (click)="openGallery()" />
      </div>
      <div class="col-sm-8">
        <div class="shopping-digital mb-3">
          <div class="row mt-3 mt-sm-0">
            <div class="col-12 col-sm-8 text-center text-sm-start">
              <span class="badge bg-primary rounded-0 px-4 py-2">{{ event.saleType === 'pre-bid' ? translations.virtual_bid : translations.digital_shopping }}</span>
            </div>
            <div *ngIf="!event.hideTimers" class="col-12 col-sm-4 mt-4 mt-sm-0">
              <app-timer [endAt]="event.endDate"></app-timer>
            </div>
          </div>
        </div>
        <div *ngIf="!event.hideTimers" class="row dates mt-1">
          <div class="col-6 col-sm-4 d-flex">
            <img class="img-fluid" src="/assets/clock.svg" width="18px" />
            <span class="ms-2">
              <span class="text-muted" style="font-size: 0.75rem">{{ translations.start }}</span><br />
              <span class="dates-date" style="font-weight: bold">{{
                event.date | date: "dd/MM 'às' HH:mm"
                }}</span>
            </span>
          </div>
          <div class="col-6 col-sm-4 d-flex">
            <img class="img-fluid" src="/assets/clock.svg" width="18px" />
            <span class="ms-2">
              <span class="text-muted" style="font-size: 0.75rem">{{ translations.end }}</span><br />
              <span class="dates-date"  style="font-weight: bold">{{
                event.endDate | date: "dd/MM 'às' HH:mm"
                }}</span>
            </span>
          </div>
        </div>
        <div id="partner-description" class="mt-5">
          <div [outerHTML]="event.description"></div>
        </div>
        <div class="lead">
          <form name="lead" [formGroup]="form" (ngSubmit)="lead()" action="javascript:void(0);">
            <div class="row">
              <div class="col-sm-3">
                <div>
                  <input id="name" name="name" type="text" class="form-control " autocomplete="name" placeholder="Nome"
                    formControlName="name" required />
                </div>
              </div>
              <div class="col-sm-3">
                <div>
                  <input id="email" name="email" type="email" class="form-control " autocomplete="email"
                    placeholder="Email" formControlName="email" required />
                </div>
              </div>
              <div class="col-sm-3">
                <div>
                  <input id="phone" name="phone" type="tel" class="form-control" autocomplete="tel"
                    placeholder="Telefone/Whatsapp" mask="(00) 0000-0000||(00) 00000-0000" formControlName="phone"  required />
                </div>
              </div>
              <div class="col-sm-3">
                <button type="submit" class="btn btn-primary w-100 btn-negotiate" [disabled]="!form.valid">
                  {{ translations.interested }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- TODO: Arquivos e links do evento -->
        <!-- TODO: Ordenação pelo order (via api) -->
        <div class="partner-links mt-4">
          <div class="d-flex align-items-center" style="gap: 10px">
            <a [href]="link.url" [attr.target]="link.targetBlank ? '_blank' : ''" class="link-item"
              *ngFor="let link of event.auctionLinks">
              <img src="/assets/links/{{ link.imageName }}" alt="" />
              <span class="text-muted">{{ link.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="filtering">
    <div class="row">
      <div class="col-sm-9 shopping-digital my-3" *ngIf="!mostrarDivAcima">
        <div class="mt-3 mt-sm-0">
          <div class="col-12 col-sm-8 text-center text-sm-start">
            <span class="badge bg-primary rounded-0 px-4 py-2">{{ event.saleType === 'pre-bid' ? translations.virtual_bid : translations.digital_shopping }}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-9 d-none d-sm-block" *ngIf="mostrarDivAcima"></div>
      <div class="col-sm-3 my-3">
        <select class="drop-down-ordenar form-select partner-product-filter" #sortSelect
          (change)="onSortChange(sortSelect.value)">
          <option selected value="">{{ translations.order_by }}</option>
          <option value="low-price">{{ translations.lowest_price }}</option>
          <option value="high-price">{{ translations.highest_price }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="partner-product-list">
    <div class="row">
      <div class="col-xl-3 col-md-4 col-sm-6 mb-3" *ngFor="let product of lots">
        <app-product-card [product]="product" [routerLink]="['produtos', product.id]"></app-product-card>
      </div>
    </div>
  </div>
  <div class="partner-info mb-3" *ngIf="!mostrarDivAcima">
    <div class="row" >
      <div class="col-sm-12">
        <!-- TODO: Arquivos e links do evento -->
        <!-- TODO: Ordenação pelo order (via api) -->
        <div class="partner-links mt-4">
          <div class="d-flex align-items-center" style="gap: 10px">
            <a [href]="link.url" [attr.target]="link.targetBlank ? '_blank' : ''" class="link-item"
              *ngFor="let link of event.auctionLinks">
              <img src="/assets/links/{{ link.imageName }}" alt="" />
              <span class="text-muted">{{ link.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="modalLeadSuccessShow" [draggable]="false">
  <div class="my-3 mb-5 modal-success">
    <h2 class="success-title text-center"><strong>{{ translations.sent }}</strong></h2>
    <p class="text-muted my-5 text-center">
      {{ translations.thanks_for_your_interest }}
    </p>
  </div>
</p-dialog>

<p-dialog  [dismissableMask]="true" [modal]="true" [(visible)]="showGallery" [draggable]="false"
  *ngIf="event" [style]="{ 'min-width': '35vw', 'max-width': '90vw', 'max-height': '95vh' }">
  <div class="text-center gallery-container">
    <img class="img-fluid rounded" [src]="event.imageUrl" />
  </div>
</p-dialog>
