<!--Botão voltar-->
<!--<div class="go-back container">
  <span (click)="goBack()"> <img src="/assets/ic_arrow_left_blue.svg" alt="" /> &nbsp; Voltar</span>
</div>-->

<div class="container" *ngIf="products && products.length; else noResults ">
  <div class="row mt-5">
    <p>Encontramos {{ countResults }} resultados de pesquisa com a expressão
      <span style="font-weight: bold;">"{{ searchTerm }}"</span>
    </p>
    <div *ngFor="let product of products" class="card border-0 col-xl-3 col-md-4 col-sm-6 mb-3"
      (click)="productDetail(product.auctionId, product.id)"
      [routerLink]="['/eventos', product.auctionId, 'produtos', product.id]">
      <img *ngIf="product.lotImages && product.lotImages[0]" [src]="product.lotImages[0].imageUrl"
        class="card-img-top rounded m-0" alt="">
      <img src="/assets/circle-shield.svg" class="circle-shield" />
      <div class="card-body">
        <p class="card-text">{{ product.city }}, {{ product.state }} - BR </p>
        <h5 class="card-title">{{ product.title }}</h5>
        <p class="card-lot">{{ product.number }}</p>
        <!--<p class="card-price"
          *ngIf="!!product.totalValue && !!product.originalPrice && product.originalPrice > product.totalValue">{{
          product.totalValue | currency:'BRL':'symbol':'1.2-2' }}
          </p>-->
          <p class="card-price"
            *ngIf="!!product.totalValue && (!product.originalPrice || product.originalPrice > product.totalValue)">
            {{ product.totalValue | currency:'BRL':'symbol':'1.2-2' }}
          </p>
      </div>
    </div>

  </div>
</div>
<ng-template #noResults>
  <p class="container text-start mt-5">Nenhum produto encontrado para
    <span style="font-weight: bold;">"{{ searchTerm }}"</span>.
  </p>
</ng-template>
