import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { FooterModule } from './core/compoents/footer/footer.module';
import { HeaderModule } from './core/compoents/header/header.module';
import { DataModule } from './core/data/data.module';
import { CustomRouteReuseStrategy } from './core/routes/custom-route-reuse-strategy';
import { PagesComponent } from './pages/pages.component';

registerLocaleData(localePt);

const components: Type<any>[] | any[] = [
  AppComponent,
  PagesComponent,
];

@NgModule({
  declarations: components,
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    HeaderModule,
    RouterModule,
    FooterModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    ComponentsModule,
    DataModule,

    FormsModule,
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
