import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-search',
  //standalone: true,
  //imports: [],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit {

  ngOnInit(): void {

  }
  onSearch(event: Event) {

  }

}
