import { Type } from 'class-transformer';
import { Model } from '../base.model';
import { Event } from '../event/event.model';
import { File } from '../media/file.model';
import { Partner } from '../partner/partner.model';

export class Segment extends Model {
  // public name: string;
  // public icon: File;
  // public events: Event[];
  public auctionCategoryId: number;
  public subCategories: any[];
  public description: string;
  public imageComplete: string;
  public hideTitle: boolean;
  public order: number;

  public get imageUrl(): string {
    return this.imageComplete.replace('remateweb.s3.amazonaws.com', 'd13nls57a1x77f.cloudfront.net');
  }
}

export class SegmentPartners extends Segment {
  @Type(() => Partner)
  partners: Partner[];
}
