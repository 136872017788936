import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input()
  public endAt: Date;

  @Input()
  public size: 'big' | 'small' = 'big';

  public diffDate: {
    days: number;
    hours: string;
    mins: string;
    seconds: string;
  };

  public diff: Date;

  private timerInterval: any;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) { }

  ngOnInit(): void {
    this.updateDiff();

    if (isPlatformBrowser(this.platformId)) {
      this.timerInterval = setInterval(() => {
        this.updateDiff();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }

  public updateDiff(): void {
    this.diff = new Date(this.endAt.getTime() - new Date().getTime());
    this.diffDate = {
      days: Math.floor(this.diff.getTime() / (1000 * 60 * 60 * 24)),
      hours: (Math.floor(this.diff.getTime() / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
      mins: this.diff.getMinutes().toString().padStart(2, '0'),
      seconds: this.diff.getSeconds().toString().padStart(2, '0'),
    };
  }
}
