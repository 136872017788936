<div *ngIf="event" class="container">
  <div class="go-back mt-4">
    <span (click)="goBack()"> <img src="/assets/ic_arrow_left_blue.svg" alt="" /> &nbsp; {{ translations.go_back }}</span>
  </div>
  <div class="row mt-4" *ngIf="product">
    <div class="col-md-7">
      <ul id="lightSlider" class="gallery list-unstyled">
        <li *ngFor="let video of product.lotVideos" [attr.data-thumb]="video.thumbnail"
          style="height: 100%; min-height: 420px">
          <iframe class="w-100 yt-product-video" height="100%" [src]="video.embedUrl | safe" frameborder="0"
            allowfullscreen></iframe>
        </li>
        <li *ngFor="let image of product.lotImages" [attr.data-thumb]="image.imageLotUrl" style="height: 100%"
          (click)="openGallery(image.imageLotUrl)">
          <img [src]="image.imageLotUrl" alt="" class="img-fluid"
            style="width: 100%; object-fit: cover; cursor: zoom-in;" />
        </li>
      </ul>
    </div>
    <div class="col-md-5 ps-sm-4 mt-3 mt-sm-0" *ngIf="product">
      <div>
        <span class="badge bg-primary rounded-0 px-4 py-2">{{ product.badge }}</span>
      </div>
      <div class="row mt-2">
        <div class="col-12 product-name">
          <p class="m-0 text-muted"><small><small>{{ product.number }}</small></small></p>
          <p>{{ product.title }}</p>
        </div>
      </div>
      <div class="row product-address">
        <div class="col-2"><img src="/assets/shield-circle.svg" class="me-3" /></div>
        <div class="col-10">
          <a class="link-primary" [routerLink]="['/eventos', event.id]"><strong>{{ event.title }}</strong></a>
          <p class="small">{{ product.formatedCity() }}</p>
        </div>
      </div>
      <div class="product-value mt-3">
        <p class="small mb-0" style="font-size: 0.7rem">{{ product.valueTitle }}</p>
        <span class="text-muted"
          *ngIf="!!product.totalValue && !!product.originalPrice && product.originalPrice > product.totalValue">De:
          <s>{{ product.originalPrice | currency: translations.currency }}</s> {{ translations.by }}<br /></span>
        <span class="text-muted" *ngIf="!!product.totalValue"><span class="total-value">{{ product.totalValue | currency: translations.currency }}</span></span>
        <span class="text-muted" *ngIf="!product.totalValue"><span class="total-value">{{ translations.to_consult }}</span></span>
        <!-- {{ product.unitName ? '/' + product.unitName : '' }} -->
      </div>

      <div class="product-info-credit-card py-2 text-muted">
        <div [outerHTML]="product.paymentConditions"></div>
        <p class="m-0"> <small><small>{{ product.paymentConditionObservation }}</small></small></p>
      </div>

      <div *ngIf="product.saleType === 'pre-bid'">
        <div class="product-value mt-3">
          <p class="small mb-0" style="font-size: 0.7rem">{{ translations.next_bid }}</p>
          <span class="text-muted">
            <span class="total-next-value">{{ product.nextBid * product.captation | currency: translations.currency }}</span>
          </span>
        </div>
      </div>

      <button class="btn btn-primary w-100 btn-negotiate mt-4" (click)="onClickNegociar()"
        [disabled]="product.isSold(event)" [class.is-sold]="product.isSold(event)">
        <ng-container *ngIf="product.isSold(event)">
          {{ product.saleType === 'pre-bid' ? translations.pre_bid_closed : translations.sold }}
        </ng-container>
        <ng-container *ngIf="!product.isSold(event)">
          {{ product.saleType === 'pre-bid' ? translations.want_bid : translations.bargain }}
        </ng-container>
      </button>
      <!-- <div class="row product-info-freight py-2 my-4">
        <div class="col-2"><img src="/assets/frete.svg" class="me-3" /></div>
        <div class="col-10">
          <p class="mb-0">
            Frete facilitado por transportadora terceirizada. Entrega em até 20 dias úteis
          </p>
        </div>
      </div> -->
    </div>
  </div>

  <hr class="text-muted mt-5" />
  <div class="product-description mt-4" *ngIf="product">
    <div [outerHTML]="product.description"></div>
  </div>
</div>

<p-dialog [(visible)]="modalDealShow" [draggable]="false" *ngIf="product && event">
  <form class="negotiation" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-floating mb-3">
          <input id="name" type="text" class="form-control" autocomplete="name" placeholder="{{ translations.full_name }}"
            formControlName="name" required />
          <label for="name">{{ translations.full_name }}</label>
          <div *ngIf="fieldInvalid('name')" class="text-danger my-2">
            <span><small>{{ translations.name }} {{ translations.is_required }}</small></span>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input id="email" type="email" class="form-control" autocomplete="email" placeholder="E-mail*"
            formControlName="email" required />
          <label for="email">E-mail*</label>
          <div *ngIf="fieldInvalid('email')" class="text-danger my-2">
            <span><small>Email {{ translations.is_required }}</small></span>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input id="phone" type="tel" class="form-control" autocomplete="tel" placeholder="{{ translations.phone_cel }}"
            formControlName="phoneNumber" mask="(00) 0000-0000||(00) 00000-0000" required />
          <label for="phone">{{ translations.phone_cel }}</label>
          <div *ngIf="fieldInvalid('phoneNumber')" class="text-danger my-2">
            <span><small>{{ translations.phone }} {{ translations.is_required }}</small></span>
          </div>
        </div>

        <div class="form-floating mb-3" *ngFor="let extraField of event.dealExtraFields">
          <input id="ex-field-{{extraField.name}}" type="text" class="form-control" placeholder=""
            [formControlName]="extraField.name" />
          <label for="phone">{{extraField.name}}</label>
        </div>

        <div class="form-floating mb-3" [hidden]="product.saleType === 'pre-bid'">
          <textarea id="message" class="form-control mt-3" placeholder="Mensagem" formControlName="message"
            rows="4"></textarea>
          <label for="message">{{ translations.message }}</label>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div>
          <span class="badge rounded-0 px-4 py-2">{{ product.badge }}</span>
        </div>
        <div class="product-value mt-3">
          <p class="small mb-0" style="font-size: 0.7rem">{{ product.valueTitle }}</p>
          <span class="text-muted"
            *ngIf="!!product.totalValue && !!product.originalPrice && product.originalPrice > product.totalValue">De:
            <s>{{ product.originalPrice | currency: translations.currency }}</s> Por:<br /></span>
          <span class="text-muted" *ngIf="!!product.totalValue"><span class="total-value">{{ product.totalValue | currency: translations.currency }}</span></span>
          <span class="text-muted" *ngIf="!product.totalValue"><span class="total-value">{{ translations.to_consult }}</span></span>
          <!-- {{ product.unitName ? '/' + product.unitName : '' }} -->

        </div>

        <div class="product-info-credit-card py-2 text-muted">
          <div [outerHTML]="product.paymentConditions"></div>
          <p class="m-0"> <small><small>{{ product.paymentConditionObservation }}</small></small></p>
        </div>

        <div *ngIf="product.saleType === 'pre-bid'">
          <div class="product-value mt-3">
            <p class="small mb-0" style="font-size: 0.7rem">{{ translations.next_bid }}</p>
            <span class="text-muted">
              <span class="total-next-value">{{ product.nextBid * product.captation | currency: translations.currency }}</span>
            </span>
          </div>
        </div>

        <div class="mb-3">
          <div style="transform:scale(0.85);transform-origin:0;">
            <re-captcha id="recaptcha" name="recaptcha" formControlName="recaptchaResponse" data-size="compact"
              required></re-captcha>
            <div *ngIf="fieldInvalid('name')" class="text-danger my-2">
              <span><small>Recaptcha {{ translations.is_required }}</small></span>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary mt-3 w-100 btn-negotiate" (click)="onClickConfirmNegociar()"
          [disabled]="!form.valid || processing">
          {{ product.saleType === 'pre-bid' ? translations.want_bid : translations.bargain }}
        </button>

        <div *ngIf="dealRes?.success === false" class="text-danger my-2">
          <span><small>{{ dealRes?.message }}</small></span>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="modalDealSuccessShow" [draggable]="false" *ngIf="product && dealRes">
  <div class="my-3 mb-5">
    <h2 class="success-title text-center"><strong>{{ translations.sent }}</strong></h2>
    <p class="text-muted my-5">
      {{ dealRes.message }}
    </p>
  </div>
</p-dialog>

<p-dialog [dismissableMask]="true" [modal]="true" [(visible)]="showGallery" [draggable]="false" *ngIf="event"
  [style]="{ 'min-width': '35vw', 'max-width': '95vw', 'max-height': '95vh' }">
  <div class="text-center gallery-container">
    <img class="img-fluid rounded" [src]="galleryImg" />
  </div>
</p-dialog>
