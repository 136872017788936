<div class="d-flex justify-content-between timer-{{ size }} mx-auto" style="max-width: 250px">
  <div class="timer-item">
    <div>
      <span class="timer-item-value">{{ diffDate.days }}</span>
      <span class="timer-item-divisor">:</span>
    </div>
    <div class="timer-item-info">Dias</div>
  </div>
  <div class="timer-item">
    <div>
      <span class="timer-item-value">{{ diffDate.hours }}</span>
      <span class="timer-item-divisor">:</span>
    </div>
    <div class="timer-item-info">Hrs</div>
  </div>
  <div class="timer-item">
    <div>
      <span class="timer-item-value">{{ diffDate.mins }}</span>
      <span class="timer-item-divisor">:</span>
    </div>
    <div class="timer-item-info">Min.</div>
  </div>
  <div class="timer-item">
    <div>
      <span class="timer-item-value">{{ diffDate.seconds }}</span>
    </div>
    <div class="timer-item-info">Seg.</div>
  </div>
</div>
